import { Frequency, RRule, Weekday } from "rrule";
import { ScheduleCreateForm } from "./scheduler";

export function simpleFreqToRRule(
  startDate: Date | undefined,
  frequencyPresetString: ScheduleCreateForm["frequencySimplified"],
) {
  if (!startDate) {
    return undefined;
  }

  switch (frequencyPresetString) {
    case "not-repeat":
      return new RRule({
        dtstart: startDate,
        freq: Frequency.DAILY,
        count: 1,
      });
    case "daily":
      return dailyFreq(startDate);
    case "weekly":
      return weeklyDowFreq(startDate);
    case "monthly":
      return monthlyOnFirstDowFreq(startDate);
    case "yearly":
      return yearlyMonthDateFreq(startDate);
    case "weekday":
      return weekdayFreq(startDate);
    case "custom":
      return undefined;
  }
}

function dailyFreq(dt: Date) {
  return new RRule({
    dtstart: dt,
    freq: Frequency.DAILY,
  });
}

function weeklyDowFreq(dt: Date) {
  const wd = new Weekday(dt.getDay() - 1);
  return new RRule({
    dtstart: dt,
    freq: Frequency.WEEKLY,
    byweekday: wd,
  });
}

function monthlyOnFirstDowFreq(dt: Date) {
  const wd = new Weekday(dt.getDay() - 1);
  return new RRule({
    dtstart: dt,
    freq: Frequency.MONTHLY,
    byweekday: wd,
  });
}

function yearlyMonthDateFreq(dt: Date) {
  const wd = new Weekday(dt.getDay() - 1);
  return new RRule({
    dtstart: dt,
    freq: Frequency.YEARLY,
    bymonth: dt.getMonth(),
    byweekday: wd,
  });
}

function weekdayFreq(dt: Date) {
  return new RRule({
    dtstart: dt,
    freq: Frequency.WEEKLY,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  });
}
