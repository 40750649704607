//  libraries
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const CONTRACTOR_CONTACT_INFORMATION_FORM_GROUP: IFieldGroupBuilt = {
  name: "contractorContactInformation",
  label: "Contact Information",
  fields: [
    {
      fieldType: IFieldTypes.textField,
      span: 1,
      name: "name",
      label: "Primary Contact Name",
    },
    {
      name: "email",
      span: 4,
      label: "Email Address",
      fieldType: IFieldTypes.emailField,
    },
    {
      name: "officePhone",
      span: 1,
      label: "Office Phone",
      fieldType: IFieldTypes.phoneField,
    },
    {
      name: "mobilePhone",
      span: 1,
      label: "Mobile Phone",
      fieldType: IFieldTypes.phoneField,
    },
  ],
};
