import { Timestamp } from "@google-cloud/firestore";
import { DateRelative } from "../typings";

import * as Yup from "yup";

export const Time = {
  days: 24 * 60 * 60 * 1000,
  hours: 60 * 60 * 1000,
  mins: 60 * 1000,
  seconds: 1000,
  ms: 1,
};

export function nth(d: number) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// h (hours), d (days), w (weeks), m (months), y (years)
type DurationUnits = "h" | "d" | "w" | "m" | "y";

export type Duration = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
};

const DATE = Yup.date();

export function isDate(v: unknown): v is Date {
  return DATE.isValidSync(v);
}

const TimestampValidator = Yup.object({
  seconds: Yup.number().required(),
  nanoseconds: Yup.number().required(),
});

export function isTimestamp(value: unknown): value is Timestamp {
  return TimestampValidator.isValidSync(value);
}

export function dateOrTimestampAsDate(
  v?: Date | Timestamp | null,
): Date | undefined | null {
  if (!v) {
    return v;
  }

  if (isTimestamp(v)) {
    return new Timestamp(v.seconds, v.nanoseconds).toDate();
  } else if (typeof v === "string") {
    return new Date(v);
  } else {
    return v;
  }
}

export const RelativeDateRegExp = new RegExp("([0-9]+)([d,w,m,y])");
// export const RelativeDateRegExp = new RegExp("[0-9]+[d,w,m,y]", "g");

// TODO: Move RelativeDateRegExp and Duration here

export function parseRelativeDuration(v: string): Duration | undefined {
  // Extract number and unit
  const matches = v.match(RelativeDateRegExp);
  if (!matches || matches.length < 3) {
    return;
  }
  const numStr = matches[1];
  const unit = matches[2];
  const num = parseInt(numStr);
  switch (unit) {
    case "d":
      return { days: num };
    case "w":
      return { weeks: num };
    case "m":
      return { months: num };
    case "y":
      return { years: num };
    default:
      return undefined;
  }
}

export function dueDateFormToDuration(d: {
  _dueDateDuration?: number;
  _dueDateUnit?: DurationUnits;
}): DateRelative {
  const due: DateRelative = {
    dtType: "relative",
    duration: {},
  };
  switch (d._dueDateUnit) {
    case "h":
      due.duration.hours = d._dueDateDuration;
      break;
    case "d":
      due.duration.days = d._dueDateDuration;
      break;
    case "w":
      due.duration.weeks = d._dueDateDuration;
      break;
    case "m":
      due.duration.months = d._dueDateDuration;
      break;
    case "y":
      due.duration.years = d._dueDateDuration;
      break;
  }
  return due;
}

export function durationToQuantity(d: Duration): number {
  if (d.hours) {
    return d.hours;
  } else if (d.days) {
    return d.days;
  } else if (d.weeks) {
    return d.weeks;
  } else if (d.months) {
    return d.months;
  } else if (d.years) {
    return d.years;
  }
  return 0;
}

export function durationToUnit(d: Duration): DurationUnits | undefined {
  if (d.hours) {
    return "h";
  } else if (d.days) {
    return "d";
  } else if (d.weeks) {
    return "w";
  } else if (d.months) {
    return "m";
  } else if (d.years) {
    return "y";
  }
  return undefined;
}

export function durationToString(d: Duration): string {
  if (d.hours) {
    return d.hours.toString() + "h";
  } else if (d.days) {
    return d.days.toString() + "d";
  } else if (d.weeks) {
    return d.weeks.toString() + "w";
  } else if (d.months) {
    return d.months.toString() + "m";
  } else if (d.years) {
    return d.years.toString() + "y";
  }
  return "";
}

// Extracts any valid relative duration from the string and returns it
export function extractValidRelativeDuration(v: string): string | undefined {
  const str = v as string;
  const match = str.match(RelativeDateRegExp);
  const numMatches = str.match(/[0-9]+/);
  if (match && match.length > 0) {
    // Take the first match and set it if the string is not valid
    return match[0];
  } else if (numMatches) {
    // If not matching the full format, filter only for digits
    if (numMatches) {
      return numMatches[0];
    }
  } else {
    // The input is not a fully valid or partially valid value
    return "";
  }
}
